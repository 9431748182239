import { FC, useRef, useEffect, useState } from 'react';
import { Badge, List, Avatar, message as $message } from 'antd';
import { useNavigate } from 'react-router-dom';
import IconFont from '../IconFont';
import LogModal from '@/components/LogModal';
import parse from 'html-react-parser';
import { store } from '@/store';
import { removeImgTagsWithRegex } from '@/utils/index';
import { setMessageCount } from '@/store/features/init';
import { useAppSelector } from '@/store/hooks';

import './index.less';
import { getMessageCount, getMessageList, getMessageRead } from '@/config/api/user';

const EmptyText = (
  <div className="emptyContent">
    <IconFont type="icon-wuxiaoxi" />
    <p>暂无消息哦~</p>
  </div>
);

const BadgeInformation: FC = () => {
  const navigate = useNavigate();
  const message_count = useAppSelector((state) => state.init.message_count);
  const message_info: any = useAppSelector((state) => state.init.message_info);
  const info: any = useAppSelector((state) => state.init.info);
  const refLogModal = useRef<any>(null);
  const [list, setList] = useState<any>([]);

  function getCount() {
    getMessageCount().then((res: any) => {
      const { code, data, msg } = res;
      if (code !== 0) {
        if (res.code === 2164260881) {
          return false;
        }
        return $message.error(msg);
      }
      store.dispatch(setMessageCount(data.count));
    });
  }

  /**
   * 搜索数据
   * @param page
   * @param page_size
   */
  const getList = async () => {
    const res: any = await getMessageList({
      is_read: 2,
      type: 1,
      page: 1,
      page_size: 4,
    });
    const { code, msg, data } = res;
    if (code !== 0) {
      if (res.code === 2164260881) {
        return false;
      }
      return $message.error(msg);
    }
    if (message_info?.id) {
      if (data.list.length) {
        setList(data?.list.splice(1, 4));
      } else {
        setList([]);
      }
    } else {
      setList(data?.list.splice(0, 3) || []);
    }
  };

  function handleInfo(item: any) {
    refLogModal?.current.init(item.id);
    if (item.is_read === 2) {
      const Index = list.findIndex((v: any) => v.id === item.id);
      list.splice(
        Index,
        1,
        Object.assign({ ...list.find((v: any) => v.id === item.id), is_read: 1 })
      );
      setList([...list]);
      if (message_count > 0) {
        store.dispatch(setMessageCount(message_count - 1));
      }
    }
  }

  const handleGotoNewList = () => {
    navigate('/user/new_list');
  };

  // 全部已读
  const handleReadMessage = () => {
    getMessageRead({
      ids: [],
    }).then((res: any) => {
      const { code, msg } = res;
      if (code !== 0) {
        return $message.error(msg);
      }
      setList([]);
      store.dispatch(setMessageCount(0));
    });
  };

  /**
   * 刷新页面
   */
  const onReset = () => {
    getList();
  };

  useEffect(() => {
    getCount();
  }, []);

  useEffect(() => {
    if (info?.id) {
      getList();
    }
  }, [info]);

  return (
    <>
      <div className="badgeContainer">
        <Badge count={message_count} size="small">
          <IconFont type="icon-xiaoxi" className="icon" />
        </Badge>
        <List
          className="badgeCardList"
          header={<div>消息中心</div>}
          footer={
            <div className="cardFooter flex-row-between">
              <p onClick={handleReadMessage}>全部已读</p>
              <p onClick={handleGotoNewList}>
                查看全部消息
                <IconFont type="icon-right" />
              </p>
            </div>
          }
          bordered
          dataSource={list}
          rowKey={(item: any) => `notice_${item.id}`}
          locale={{ emptyText: EmptyText }}
          renderItem={(item: any) => (
            <List.Item onClick={() => handleInfo(item)}>
              <List.Item.Meta
                avatar={<Avatar size={36}>AI</Avatar>}
                title={
                  <>
                    <p className="title text-ellipsis">{item.title}</p>
                    {item.is_read === 2 && <span />}
                  </>
                }
                description={
                  <div className="text-ellipsis badge-item-content">
                    {parse(removeImgTagsWithRegex(item.content))}
                  </div>
                }
              />
              <div className="time">{item.updated_at}</div>
            </List.Item>
          )}
        />
      </div>
      <LogModal ref={refLogModal} reset={() => onReset()} />
    </>
  );
};

export default BadgeInformation;
