import { notification, Modal } from 'antd';
import axios, { AxiosRequestHeaders, AxiosResponse, AxiosError } from 'axios';

import ls from './storage';

import { logout } from '@/config/api/login';
import { clearUserInfo } from '@/utils';
import { WHALE_WISDOM_CLOUDS_TOKEN, BIND_TOKEN } from '@/utils/config';

// 创建 axios 实例   withCredentials: true,
const service = axios.create({
  // API 请求的默认前缀
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: '',
  timeout: 1000 * 60 * 6, // 请求超时时间
  responseType: 'json',
});

// 异常拦截处理器
const errorHandler = (error: AxiosError) => {
  if (error && error.message && error.message.includes('timeout')) {
    notification.error({
      message: '请求超时',
      description: '请重试',
      duration: 0,
    });
  }
  if ((error as any).data) {
    const data = (error as any).data;
    if (data.code === 403) {
      notification.error({
        message: '请求错误',
        description: data.message,
      });
    }
  }
  return Promise.reject(error);
};

// 请求拦截
service.interceptors.request.use((config) => {
  if (!navigator.onLine) {
    notification.error({
      message: '网络断开',
      description: '请检查网络',
    });
  }
  const token = ls.get(WHALE_WISDOM_CLOUDS_TOKEN);
  const bindToken = ls.get(BIND_TOKEN);
  //const token = 'c90587b1d306a0dd50acbf277afff86c';
  const Authorization = token ? `Bearer ${token}` : `Bearer ${bindToken}`;
  if (token) (config.headers as AxiosRequestHeaders)['X-Token'] = token;
  if (Authorization) (config.headers as AxiosRequestHeaders)['Authorization'] = Authorization;
  return config;
}, errorHandler);

/**
 * 多账号登录错误
 */
const onErrorMultiAccount = () => {
  Modal.error({
    icon: null,
    closable: true,
    content: '用户已在其他设备登录，请重新登录',
    cancelText: '取消',
    okText: '确认',
    onOk() {
      logout().then(() => {
        logoutHandler();
      });
    },
    onCancel() {
      logout().then(() => {
        logoutHandler();
      });
    },
  });
};
const logoutHandler = () => {
  clearUserInfo();
  window.location.replace(`${window.location.origin}/welcome`);
};

// 响应拦截
let hasExist = false;

service.interceptors.response.use((res: AxiosResponse<any>) => {
  // 身份已失效，请重新登录
  if (res.data.code === 401 || res.data.code === 2164260881) {
    if (!hasExist) hasExist = true;
    hasExist = true;
    if (res.data.code === 401) {
      notification.error({
        message: '提示',
        description: '身份已失效，请重新登录',
        duration: 0,
      });
      const timer = setTimeout(() => {
        logoutHandler();
        timer && clearTimeout(timer);
      }, 1500);
    } else {
      onErrorMultiAccount();
    }
  } else {
    hasExist = false;
  }
  return res.data;
}, errorHandler);

// 通用get
const $get = (url: string, params?: object, _object = {}): Promise<any> => {
  return service.get(url, { params, ..._object });
};
// 通用post
const $post = (url: string, params?: object, _object = {}): Promise<any> => {
  return service.post(url, params, _object);
};

export { $get, $post };

export { service as axios };
