import { Modal, Tooltip, Divider, message as $message, Card } from 'antd';
import { useState, useEffect, forwardRef, Ref, useImperativeHandle } from 'react';
import { useAppSelector } from '@/store/hooks';
import classnames from 'classnames';

import yunduoYDMB from '@/assets/yunduo-ydmb.png';
import yunduo from '@/assets/yunduo.png';
import './index.less';
import qiye from '@/assets/qiye.png';
import geren from '@/assets/geren.png';
import free from '@/assets/free.png';
import IconFont from '@/components/IconFont';

// API
import { accountInfo, accountList } from '@/config/api/login';

const originFlag = window.location.hostname.includes('skyink');

const ChooseAccoutModal = forwardRef((_props, ref: Ref<any>) => {
  const userInfo: any = useAppSelector((state) => state.init.info);
  const [chooseAccoutModal, setChooseAccoutModal] = useState<boolean>(false); // 是否切换个人/企业用户弹窗展示
  const accountInfos: any = useAppSelector((state) => state.init.account_info);
  const [listData, setListData] = useState<any[]>([]);
  /**
   * 关闭切换弹窗
   */
  function handleCancel() {
    changeAccout(accountInfos, true);
  }

  function init() {
    setChooseAccoutModal(true);
  }

  /**
   * 切换账户 type 有值时不需要切换
   */
  const changeAccout = async (item: any, type?: boolean) => {
    // 如果为企业版用户，过期不可切换账户（不可使用对话和应用）
    if (item.type === -1 && item.company_id !== 0) {
      $message.warning('企业套餐已过期，请联系商务');
      return false;
    }
    const res: any = await accountInfo({
      company_id: item.company_id,
    });
    if (res.code === 0) {
      if (!type) {
        $message.success('切换成功');
        // 刷新页面
        window.location.replace(location.origin + '/home');
      } else {
        setChooseAccoutModal(false);
      }
    } else {
      if (res.code === 2164260881) {
        return false;
      }
      $message.error(res.msg);
    }
  };

  /**
   * 获取账户列表
   */
  async function getAccountsList() {
    const res = await accountList({});
    if (res.code === 0) {
      setListData(res.data || []);
    }
  }

  useEffect(() => {
    getAccountsList();
  }, []);

  useImperativeHandle(ref, () => ({
    init,
  }));

  return (
    <>
      <Modal
        wrapClassName="chooseAccoutModal"
        width={424}
        open={chooseAccoutModal}
        centered
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        title={'选择组织'}
      >
        <div>
          <Card className="card1">
            <div className="cardContent">
              <div
                className="cardItem flex-row-around"
                onClick={() => changeAccout(accountInfos, true)}
              >
                <div className="avatarImage">
                  <img src={userInfo?.avatar} />
                </div>
                <div className="avatarText">
                  <div className="avatarText1">
                    <Tooltip title={accountInfos?.title || userInfo?.nickname}>
                      <div className="title">{accountInfos?.title || userInfo?.nickname}</div>
                    </Tooltip>
                    <img
                      className="img"
                      src={
                        accountInfos?.company_id !== 0
                          ? qiye
                          : accountInfos.type === 1
                          ? geren
                          : free
                      }
                    />
                  </div>
                  <div className="avatarText2">
                    {accountInfos?.company_id === 0 ? '个人' : '企业'}
                    {accountInfos?.company_id !== 0 && ` :  ${accountInfos?.title}`}
                    <Divider type="vertical" />
                    {accountInfos?.balance || 0}
                    <img src={originFlag ? yunduoYDMB : yunduo} className="image2" />
                  </div>
                </div>
                <div className="avatarIcon">
                  <IconFont type="icon-chenggong" className="icon" />
                </div>
              </div>
              <Divider className="dividerV" />
              {listData.map((item: any, index: number) => {
                if (item.company_id !== accountInfos.company_id) {
                  return (
                    <div
                      className={classnames('cardItem marginTop flex-row-around', {
                        disabledCardItem: item.type === -1 && item.company_id !== 0,
                      })}
                      key={'cardItem' + index}
                      onClick={() => changeAccout(item)}
                    >
                      <div className="avatarImage">
                        <img src={item?.avatar || userInfo?.avatar} />
                      </div>
                      <div className="avatarText">
                        <div className="avatarText1">
                          <Tooltip title={item?.nickname || item?.name}>
                            <div className="title">{item?.nickname || item?.name}</div>
                          </Tooltip>
                          <img
                            className="img"
                            src={item?.company_id !== 0 ? qiye : item.type === 1 ? geren : free}
                          />
                        </div>
                        <div className="avatarText2">
                          {item?.company_id === 0 ? '个人' : '企业'}
                          {item?.company_id !== 0 && ` :  ${item?.name}`}
                          <Divider type="vertical" />
                          {item?.balance || 0}
                          <img src={originFlag ? yunduoYDMB : yunduo} className="image2" />
                        </div>
                      </div>
                      <div className="avatarIcon"></div>
                    </div>
                  );
                }
              })}
            </div>
          </Card>
        </div>
      </Modal>
    </>
  );
});
ChooseAccoutModal.displayName = 'ChooseAccoutModal';

export default ChooseAccoutModal;
