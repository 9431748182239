import { Layout } from 'antd';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';

import HeadTop from '@/components/Header';
import RouterBeforeEach from '@/config/routes/RouterBeforeEach';

import './MainLayout.less';

const { Header, Content } = Layout;

const PluginLayout: React.FC = () => {
  const [currentBgColor, setCurrentBgColor] = useState<string>('them');

  useEffect(() => {
    if (window.location.pathname !== '/document_examine') {
      setCurrentBgColor('not');
    } else {
      setCurrentBgColor('them');
    }
  }, []);

  return (
    <Layout className={'mainLayout'}>
      <Header className={'header'}>
        <HeadTop />
      </Header>
      <Layout>
        <Layout
          className={classNames('layoutContent', {
            layoutBgColor: currentBgColor === 'not',
          })}
        >
          <Content className={'content'}>
            <RouterBeforeEach />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PluginLayout;
