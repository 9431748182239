import { $get, $post } from '@/utils/axios';
import { cloudsApi } from '@/utils/https';

// 用户登录
export async function login(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/login', parameter);
}

/**
 * 获取用户信息
 */
export async function getUserInfo(parameter?: any, _object?: any): Promise<any> {
  return $post(cloudsApi + '/user/info', parameter, _object);
}

// 用户信息收集更新
export async function memberinfoUpdate(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/memberinfo-update', parameter);
}

// 发送短信
export async function smsSend(parameter: any): Promise<any> {
  return $post(cloudsApi + '/sms/send', parameter);
}

// 退出登录
export async function logout(): Promise<any> {
  return $post(cloudsApi + '/user/loginout');
}

// 用户账户列表接口
export async function accountList(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/account-list', parameter);
}

// 用户账户切换及账户信息
export async function accountInfo(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/account-info', parameter);
}

//  ---------二维码登录part-----------
export async function getWhaleUserInfo(parameter?: any, _object?: any): Promise<any> {
  return $get('/user/info', parameter, _object);
}

/**
 * 邀请链接备注
 */
export async function mb_expand(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/mb_expand', parameter);
}

/** 获取验证码 **/
export async function getVerificationCodeService(parameter: any, _object?: any): Promise<any> {
  return $post(cloudsApi + '/sms/send', parameter, _object);
}

/**
 * 微信公众号获取二维码url
 */
export async function wxCode(parameter: any): Promise<any> {
  return $post(cloudsApi + '/wx/code', parameter);
}

/**
 * 微信公众号轮询获取登录结果
 */
export async function wxScan(parameter: any): Promise<any> {
  return $get(cloudsApi + '/wx/scan', parameter);
}

/**
 * 微信公众号绑定手机号
 */
export async function bindPhone(parameter: any, _object?: any): Promise<any> {
  return $post(cloudsApi + '/user/bind_phone', parameter, _object);
}

/**
 * 微信登录状态同意用户协议
 */
export async function userAgreement(parameter: any, _object?: any): Promise<any> {
  return $post(cloudsApi + '/user/agreement', parameter, _object);
}
