import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';

import serviceYDMB from '@/assets/recharge/service-ydmb.png';
import service from '@/assets/recharge/service.png';
import HeadTop from '@/components/Header';
import LogModal from '@/components/LogModal';
import SideMenu from '@/components/Menu';
// import NjoyBreadcrumb from '@/components/NjoyBreadcrumb';
import RouterBeforeEach from '@/config/routes/RouterBeforeEach';
import { themeConfig } from '@/config/style/themeConfig';
import { useAppSelector } from '@/store/hooks';
import { isOriginSkyink } from '@/utils/index';

import './MainLayout.less';

const { Header, Content, Sider } = Layout;

const originQR = isOriginSkyink() ? serviceYDMB : service;

const MainLayout: React.FC = () => {
  // ref
  const refLogModal = useRef<any>(null);

  // const currentYear = new Date().getFullYear();
  const [collapsed, setCollapsed] = useState(false);

  const message_info: any = useAppSelector((state) => state.init.message_info);

  // 修改布局
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (message_info?.id) {
      refLogModal?.current.init(message_info.id);
    }
  }, [message_info]);

  return (
    <Layout className={'mainLayout'}>
      <Header className={'header'}>
        <HeadTop />
      </Header>
      <Layout>
        <Sider
          width={208}
          collapsedWidth={50}
          className={classNames('sider', { padding0: collapsed })}
          collapsed={collapsed}
        >
          <SideMenu collapsed={collapsed} />
          {!collapsed && (
            <div className="service-qr">
              <img src={originQR} alt="" />
              <p>更多合作请扫码咨询</p>
            </div>
          )}

          <div className="collapsed" onClick={toggleCollapsed}>
            {collapsed ? (
              <MenuUnfoldOutlined style={{ color: themeConfig.token.colorPrimary }} />
            ) : (
              <MenuFoldOutlined style={{ color: themeConfig.token.colorPrimary }} />
            )}
          </div>
        </Sider>
        <Layout className={'layoutContent'}>
          {/* <NjoyBreadcrumb /> */}
          <Content className={'content'}>
            <RouterBeforeEach />
          </Content>
          {/* <Footer className={'footer'}>©{currentYear} 乐推网络科技有限公司</Footer> */}
        </Layout>
      </Layout>
      <LogModal ref={refLogModal} />
    </Layout>
  );
};

export default MainLayout;
