import { useRef, type FC, useEffect } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Divider, Dropdown } from 'antd';
import logo from '@/assets/logo.png';
import logoYDMB from '@/assets/logo-ydmb.svg';
import logoFC from '@/assets/logo_fanchun.svg';
import create from '@/assets/create.png';
import createYDMB from '@/assets/create-ydmb.png';
import yunduo from '@/assets/yunduo.png';
import yunduoYDMB from '@/assets/yunduo-ydmb.png';
import './index.less';
import { getPasswordStatus } from '@/utils/index';
// 组件
import { useNavigate } from 'react-router-dom';
import AvatarDropdown from '@/components/AvatarDropdown';
import BadgeInformation from '@/components/BadgeInformation';
import IconFont from '../IconFont';
import { useAppSelector } from '@/store/hooks';
import { computerDay } from '@/utils';
import eventBus, { OPEN_VIP_MODAL } from '@/utils/events';
import RechargeModal from '@/components/RechargeModal';
import ChooseAccoutModal from '@/components/ChooseAccoutModal';

type headerProps = {
  showHeader?: boolean;
};

const originFlag = window.location.hostname.includes('skyink');
const Header: FC<headerProps> = (props) => {
  const navigate = useNavigate();
  // ref
  const refRechargeModal = useRef<any>(null);
  const refChooseAccoutModal = useRef<any>(null);
  const accountInfo: any = useAppSelector((state) => state.init.account_info);
  const { showHeader = true } = props;

  useEffect(() => {
    getPasswordStatus();
    eventBus.addListener(OPEN_VIP_MODAL, getMore);
    return () => {
      eventBus.addListener(OPEN_VIP_MODAL, getMore);
    };
  }, []);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="drop-target-item"
          href="https://mobo9os4ud.feishu.cn/docx/IK9IdD8GdoXtoAxV9uvcWrxknvb"
        >
          {`${originFlag ? '云端妙笔' : '鲸智云'}操作手册`}
        </a>
      ),
    },
  ];
  /**
   * 显示弹窗 购买权益
   * @param type vip 打开VIP  more 打开流量包
   */
  const getMore = (type: string) => {
    switch (type) {
      case 'vip':
        refRechargeModal?.current.init();
        break;
      case 'more':
        navigate(`/user/refueling`);
        break;
      case 'choose':
        refChooseAccoutModal?.current.init();
        break;
      default:
        refRechargeModal?.current.init();
        break;
    }
  };

  return (
    <div className="headerContainer">
      <div className="leftContent">
        {accountInfo.company_id === 15 ? (
          <img src={logoFC} className="fcLogo" alt="logo" />
        ) : (
          <img src={originFlag ? logoYDMB : logo} className="appLogo" alt="logo" />
        )}
      </div>
      {showHeader && (
        <div className="centerContent">
          <div className="centerItem">
            <img src={originFlag ? createYDMB : create} className="image1" />
            <div className="iconFont1">创作权益</div>
          </div>
          <div className="centerItem">
            <div className="iconFont3">{originFlag ? '云朵币' : '鲸币'}余</div>
            <div className="iconFont2">{accountInfo?.balance || 0}</div>
            <img src={originFlag ? yunduoYDMB : yunduo} className="image2" />
          </div>
          <div className="centerItem">
            <div className="iconFont3">会员有效期余</div>
            <div className="iconFont2">
              {accountInfo?.end_at ? computerDay(accountInfo.end_at) : 0}天
            </div>
          </div>
          <div className="centerItem pointer">
            {/*  0=未开通 1=会员版 2=体验版 -1=已过期  */}
            {[1, 2].includes(accountInfo?.type) ? (
              <div className="iconFont1" onClick={() => getMore('more')}>
                {'获得更多'}
              </div>
            ) : (
              <div className="iconFont1" onClick={() => getMore('vip')}>
                {'充值会员'}
              </div>
            )}

            <IconFont type="icon-right" className="icon" />
          </div>
        </div>
      )}
      <div className="rightContent">
        <Dropdown menu={{ items }} placement="bottomRight">
          <a onClick={(e) => e.preventDefault()} className="qa">
            <QuestionCircleOutlined />
          </a>
        </Dropdown>
        <BadgeInformation />
        <Divider type="vertical" className="divider" />
        <AvatarDropdown />
      </div>
      <RechargeModal ref={refRechargeModal} />
      <ChooseAccoutModal ref={refChooseAccoutModal} />
    </div>
  );
};
export default Header;
