import { useState, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Divider, Card, message, Modal, Tooltip } from 'antd';
import { UserOutlined, InfoCircleFilled } from '@ant-design/icons';
import qiye from '@/assets/qiye.png';
import geren from '@/assets/geren.png';
import free from '@/assets/free.png';
import avatar1 from '@/assets/1.png';
import avatar2 from '@/assets/2.png';
import avatar3 from '@/assets/3.png';
import avatar1YDMB from '@/assets/1-ydmb.png';
import avatar2YDMB from '@/assets/2-ydmb.png';
import avatar3YDMB from '@/assets/3-ydmb.png';
import classnames from 'classnames';
import { useAppSelector } from '@/store/hooks';
import './index.less';
import { accountList, accountInfo } from '@/config/api/login';
import { clearUserInfo } from '@/utils';
// api
import { logout } from '@/config/api/login';
import IconFont from '../IconFont';
const originFlag = window.location.hostname.includes('skyink');
//interface Props {
//  menu?: MenuProps['items'];
//  overlayClassName?: string;
//  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter';
//}

const { confirm } = Modal;

const AvatarDropdown: FC = () => {
  const navigate = useNavigate();
  const userInfo: any = useAppSelector((state) => state.init.info);
  const accountInfos: any = useAppSelector((state) => state.init.account_info);
  const [isShowSubject, setIsShowSubject] = useState<boolean>(false); // 是否显示主体
  const [isShowData, setIsShowData] = useState<boolean>(false); //

  const [listData, setListData] = useState<any[]>([]);

  //// 会员状态
  //const vipTypes: any = {
  //  '-1': '已过期',
  //  0: '未开通',
  //  1: '会员版',
  //  2: '体验版',
  //};

  /**
   *	显示切换主题页面
   */
  const showSubject = async () => {
    if (!isShowSubject) {
      const res = await accountList({});
      if (res.code === 0) {
        setListData(res.data || []);
      }
    }
    setIsShowSubject(!isShowSubject);
    const timer = setTimeout(() => {
      setIsShowData(!isShowData);
      timer && clearTimeout(timer);
    }, 100);
  };

  /**
   *  鼠标移动
   */
  const onMouse = (type: string) => {
    if (type === 'leave') {
      setIsShowSubject(false);
      setIsShowData(false);
    }
  };

  /**
   * 切换账户 type 有值时不需要切换
   */
  const changeAccout = async (item: any, type?: boolean) => {
    // 如果为企业版用户，过期不可切换账户（不可使用对话和应用）
    if (item.type === -1 && item.company_id !== 0) {
      message.warning('企业套餐已过期，请联系商务');
      return false;
    }
    const res: any = await accountInfo({
      company_id: item.company_id,
    });
    if (res.code === 0) {
      if (!type) {
        message.success('切换成功');
        // 刷新页面
        // window.location.replace(location.origin + '/home');
        navigate('/home');
        window.location.reload();
      }
    } else {
      if (res.code === 2164260881) {
        return false;
      }
      message.error(res.msg);
    }
  };

  /**
   * 按钮点击
   * @param type user：个人中心、logout：退出登录
   */
  const clickButton = (type: string) => {
    switch (type) {
      case 'user':
        navigate('/user/info');
        break;
      case 'logout':
        confirm({
          icon: <InfoCircleFilled />,
          title: '退出登录',
          maskClosable: false,
          content: (
            <div>
              <p>您确定要退出登录吗？</p>
            </div>
          ),
          onOk: () => {
            logout().then(() => {
              clearUserInfo();
              window.location.replace(`${window.location.origin}/welcome`);
            });
          },
        });
        break;
    }
  };

  return (
    <>
      <div
        className="avatarContainer"
        onMouseEnter={() => onMouse('enter')}
        onMouseLeave={() => onMouse('leave')}
      >
        <Avatar
          size={34}
          className="avatar"
          src={userInfo?.avatar}
          icon={<UserOutlined />}
          alt="avatar"
        />
        {originFlag ? (
          <img
            src={
              accountInfos?.company_id !== 0
                ? avatar3YDMB
                : accountInfos.type === 1
                ? avatar2YDMB
                : avatar1YDMB
            }
            alt=""
            className="avatar_logo"
          />
        ) : (
          <img
            src={
              accountInfos?.company_id !== 0 ? avatar3 : accountInfos.type === 1 ? avatar2 : avatar1
            }
            alt=""
            className="avatar_logo"
          />
        )}
        {/* <p>{userInfo.nickname}</p> */}

        <div className="avatarContent">
          <Card className="card">
            <div className="cardItem flex-row-around" onClick={() => showSubject()}>
              <div className="avatarImage">
                <img src={userInfo?.avatar} />
              </div>
              <div className="avatarText">
                <div className="avatarText1">
                  <Tooltip title={userInfo?.nickname || userInfo?.name}>
                    <div className="title">{userInfo?.nickname || userInfo?.name}</div>
                  </Tooltip>
                  {/*{accountInfos.type
										? [1, 2].includes(accountInfos.type) && (
												<img
													className="img"
													src={
														accountInfos?.company_id !== 0
															? qiye
															: (accountInfos.type === 1 ? geren : free)
													}
												/>
										  )
										: ''}*/}
                  <img
                    className="img"
                    src={
                      accountInfos?.company_id !== 0 ? qiye : accountInfos.type === 1 ? geren : free
                    }
                  />
                </div>
                <div className="avatarText2">
                  {/*{vipTypes[accountInfos.type || 0]} {' | '}{' '}*/}
                  {/* 用户账户类型，为0则为个人账户 */}
                  {accountInfos?.company_id === 0 ? '个人' : '企业'}
                  {accountInfos?.company_id !== 0 && (
                    <span className="company_name text-ellipsis">{` :  ${accountInfos?.title}`}</span>
                  )}
                </div>
              </div>
              <div className="avatarIcon">
                <IconFont type="icon-swap" className="icon" />
              </div>
            </div>
            <Divider className="dividerV1" />
            <div className="cardText flex-row-start" onClick={() => clickButton('user')}>
              个人中心
            </div>
            <div className="cardText flex-row-start" onClick={() => clickButton('logout')}>
              退出登录
            </div>
          </Card>
        </div>

        <div
          className={classnames('avatarContent2', {
            show: isShowSubject,
          })}
        >
          <Card className="card1">
            <div className="cardContent">
              <div className="cardItem selected flex-row-around">
                <div className="avatarImage">
                  <img src={userInfo?.avatar} />
                </div>
                <div className="avatarText">
                  <div className="avatarText1">
                    <Tooltip title={accountInfos?.title || userInfo?.nickname}>
                      <div className="title">{accountInfos?.title || userInfo?.nickname}</div>
                    </Tooltip>
                    {/*{accountInfos.type
											? [1, 2].includes(accountInfos.type) && (
													<img
														className="img"
														src={
															accountInfos?.company_id !== 0
															? qiye
															: (accountInfos.type === 1 ? geren : free)
														}
													/>
											  )
											: ''}*/}
                    <img
                      className="img"
                      src={
                        accountInfos?.company_id !== 0
                          ? qiye
                          : accountInfos.type === 1
                          ? geren
                          : free
                      }
                    />
                  </div>
                  <div className="avatarText2">
                    {/*{vipTypes[accountInfos.type || 0]} {' | '}{' '}*/}
                    {accountInfos?.company_id === 0 ? '个人' : '企业'}
                    {accountInfos?.company_id !== 0 && ` :  ${accountInfos?.title}`}
                  </div>
                </div>
                <div className="avatarIcon">
                  <IconFont type="icon-check-circle-filled" className="icon" />
                </div>
              </div>
              <Divider className="dividerV" />
              {isShowData &&
                listData.map((item: any, index: number) => {
                  if (item.company_id !== accountInfos.company_id) {
                    return (
                      <div
                        className={classnames('cardItem marginTop flex-row-around', {
                          disabledCardItem: item.type === -1 && item.company_id !== 0,
                        })}
                        key={'cardItem' + index}
                        onClick={() => changeAccout(item)}
                      >
                        <div className="avatarImage">
                          <img src={item?.avatar || userInfo?.avatar} />
                        </div>
                        <div className="avatarText">
                          <div className="avatarText1">
                            <Tooltip title={item?.nickname || item?.name}>
                              <div className="title">{item?.nickname || item?.name}</div>
                            </Tooltip>
                            {item.type
                              ? [1, 2].includes(item.type) && (
                                  <img
                                    className="img"
                                    src={
                                      item?.company_id !== 0 ? qiye : item.type === 1 ? geren : free
                                    }
                                  />
                                )
                              : ''}
                          </div>
                          <div className="avatarText2">
                            {/*{vipTypes[item.type || 0]} {' | '}{' '}*/}
                            {item?.company_id === 0 ? '个人' : '企业'}
                            {item?.company_id !== 0 && ` :  ${item?.name}`}
                          </div>
                        </div>
                        <div className="avatarIcon"></div>
                      </div>
                    );
                  }
                })}
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AvatarDropdown;
