import { Layout } from 'antd';
import React from 'react';

import HeadTop from '@/components/Header';
// import NjoyBreadcrumb from '@/components/NjoyBreadcrumb';
import RouterBeforeEach from '@/config/routes/RouterBeforeEach';

import './MainLayout.less';

const { Header, Content } = Layout;

const MainLayout: React.FC = () => {
  // const currentYear = new Date().getFullYear();

  return (
    <Layout className={'mainLayout'}>
      <Header className={'header'}>
        <HeadTop showHeader={false} />
      </Header>
      <Layout>
        <Layout className={'layoutContent'}>
          {/* <NjoyBreadcrumb /> */}
          <Content className={'content'}>
            <RouterBeforeEach />
          </Content>
          {/* <Footer className={'footer'}>©{currentYear} 乐推网络科技有限公司</Footer> */}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
