import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface initState {
  token: string;
  info: {
    name?: string;
    phone?: string;
    nickname?: string;
    avatar?: string;
    inviter_info?: string;
    check_account?: number;
  };
  account_info: any;
  menus: any;
  message_info: any;
  company_info: any;
  message_count: number;
  free_times: number; // 免费次数 对话上限5 应用上限20
  pwd_status: boolean; // 是否设置了登录密码
}

const initialState: initState = {
  token: '',
  info: {},
  menus: {},
  account_info: {},
  message_info: {},
  company_info: {},
  message_count: 0,
  free_times: 1,
  pwd_status: false,
};

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setUserToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUserInfo: (state, action) => {
      state.info = action.payload;
    },
    setAccountInfo: (state, action) => {
      state.account_info = action.payload;
    },
    setCompanyInfo: (state, action) => {
      state.company_info = action.payload;
    },
    setMenus: (state, action) => {
      state.menus = action.payload;
    },
    setMessageInfo: (state, action) => {
      state.message_info = action.payload;
    },
    setMessageCount: (state, action) => {
      state.message_count = action.payload;
    },
    setFreeTimes: (state, action) => {
      state.free_times = action.payload;
    },
    setPwdStatus: (state, action) => {
      state.pwd_status = action.payload;
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const {
  setUserToken,
  setUserInfo,
  setMenus,
  setAccountInfo,
  setCompanyInfo,
  setMessageInfo,
  setMessageCount,
  setFreeTimes,
  setPwdStatus,
} = initSlice.actions;

export default initSlice.reducer;
