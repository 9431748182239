import { Config } from '@netjoy/njoyd';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

// import ClearInfoOnClose from './clearInfoOnClose';
// import InactivityTimer from './secure';

import ico from '@/assets/favicon.ico';
import Router from '@/config/routes';
import { themeConfig } from '@/config/style/themeConfig';
// import { useAppSelector } from '@/store/hooks';
import { fetchUserInfo } from '@/utils';
import '@/config/style/common.less';
import '@/config/style/same.less';

dayjs.locale('zh-cn');
Config.set({
  theme: {
    colorPrimary: '#135EFF',
    borderRadius: 0,
  },
});

function App() {
  // const info: any = useAppSelector((state) => state.init.info);
  useEffect(() => {
    // 关闭标签清空信息 window.opener判断是否是window.open打开的标签 如果不是则清空信息并刷新页面
    // if (
    //   performance.navigation.type === performance.navigation.TYPE_NAVIGATE &&
    //   !window.opener &&
    //   info?.phone === '18852474537'
    // ) {
    //   // debugger;
    //   clearUserInfo();
    //   window.location.reload();
    // }
    // 设置描述信息
    const metaDescription = document.querySelector('meta[name="description"]');
    const link = document.querySelector('link[rel="icon"]');
    const descriptionYDMB =
      '云端妙笔，是云想科技研发的增强大语言ai应用，能够智能生成小红书笔记文案，营销文案，视频脚本';
    if (window.location.hostname.includes('skyink')) {
      // 设置标题
      document.title = document.title || '云端妙笔';

      if (metaDescription) {
        metaDescription.setAttribute('content', descriptionYDMB);
      } else {
        // 如果不存在，则创建新的meta标签
        const newMeta = document.createElement('meta');
        newMeta.setAttribute('name', 'description');
        newMeta.setAttribute('content', descriptionYDMB);
        document.head.appendChild(newMeta);
      }

      if (link) {
        link.setAttribute('href', ico);
      } else {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = ico;
        document.head.appendChild(newLink);
      }
    } else {
      document.title = document.title || '鲸智云';
    }

    // 获取用户信息 每次切换页面或者刷新 重新获取用户信息
    if (
      !window.location.pathname.includes('/login') &&
      !window.location.pathname.includes('/addInfo')
    ) {
      //debugger;
      fetchUserInfo();
    }
  }, []);

  return (
    <ConfigProvider locale={zhCN} theme={themeConfig}>
      <BrowserRouter>
        {/* The rest of your app goes here */}
        {/* <InactivityTimer /> */}
        {/* <ClearInfoOnClose /> */}
        <Router />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
