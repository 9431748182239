import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { checkRouterAuth } from './index';

import { WHALE_WISDOM_CLOUDS_TOKEN } from '@/utils/config';
import ls from '@/utils/storage';

const RouterBeforeEach = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const obj = checkRouterAuth(location.pathname);
    const blLogin = ls.get(WHALE_WISDOM_CLOUDS_TOKEN);
    if (obj && obj.auth && !blLogin) {
      setAuth(false);
      navigate('/welcome', { replace: true });
    } else {
      setAuth(true);
    }
  }, [location, navigate]);

  return auth ? <Outlet /> : null;
};

export default RouterBeforeEach;
