import { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal, message as $message, Button } from 'antd';
import './index.less';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';
// API
import { getMessageInfo, getMessageRead } from '@/config/api/user';
import IconFont from '../IconFont';
import { store } from '@/store';
import { setMessageInfo, setMessageCount } from '@/store/features/init';
import { useAppSelector } from '@/store/hooks';

interface Props {
  reset?: () => void;
}

const LogModal = forwardRef((props: Props, ref) => {
  const { reset } = props;
  const message_info: any = useAppSelector((state) => state.init.message_info);
  const message_count: any = useAppSelector((state) => state.init.message_count);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoContent, setInfoContent] = useState('');
  const [infoTitle, setInfoTitle] = useState('');

  const init = (id: number) => {
    if (id) {
      handleReadMessage(id);
      getInfo(id);
    }
  };

  const handleCancel = async () => {
    reset && reset();
    if (message_info?.id) {
      if (message_count > 0) {
        store.dispatch(setMessageCount(message_count - 1));
      }
      store.dispatch(setMessageInfo({}));
    }
    setIsModalOpen(false);
  };

  function getInfo(id: number) {
    getMessageInfo({ id }).then((res: any) => {
      const { code, msg, data } = res;
      if (code !== 0) {
        if (res.code === 2164260881) {
          return false;
        }
        return $message.error(msg);
      }
      setInfoTitle(data.title || '');
      setInfoContent(data.content || '');
      setIsModalOpen(true);
    });
  }

  // 已读
  function handleReadMessage(id: number) {
    getMessageRead({ ids: [id] }).then((res: any) => {
      const { code, msg } = res;
      if (code !== 0) {
        if (res.code === 2164260881) {
          return false;
        }
        return $message.error(msg);
      }
    });
  }

  useImperativeHandle(ref, () => ({
    init,
  }));

  return (
    <>
      <Modal
        className="messageModal"
        title={
          <>
            <IconFont type="icon-rongqi2" style={{ color: '#135EFF' }} /> 新功能上线啦
          </>
        }
        width={750}
        maskClosable={false}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <Button size="large" type="primary" onClick={handleCancel}>
            知道了
          </Button>
        }
      >
        <h3>{infoTitle}</h3>
        <div className="ql-editor">{parse(infoContent)}</div>
      </Modal>
    </>
  );
});

export default LogModal;
