import { createSlice } from '@reduxjs/toolkit';

interface initState {
  image_reference: string;
}

const initialState: initState = {
  image_reference: '',
};

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setImageReference: (state, action) => {
      state.image_reference = action.payload;
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const { setImageReference } = imageSlice.actions;

export default imageSlice.reducer;
