import { Config } from '@netjoy/njoyd';
import { init } from '@umengfe/apm';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import reportWebVitals from './reportWebVitals';

import Loading from '@/components/loading';
import { store, persistor } from '@/store';

Config.set({
  theme: {
    colorPrimary: '#135EFF',
    borderRadius: 4,
  },
});

//// 友盟 性能监控
////console.log('当前环境： ', process.env.REACT_APP_NODE_ENV);
if (process.env.REACT_APP_NODE_ENV === 'production') {
  //console.log('启动友盟监控');
  // @ts-ignore
  init({
    pageFilter: {
      mode: 'ignore',
      rules: [],
    },
    // 忽略七牛的请求
    apiFilter: { mode: 'ignore', rules: ['https://upload.qiniup.com/buckets/'] },
    // 忽略错误日志捕获
    errorFilter: {
      mode: 'ignore',
      rules: ['ResizeObserver loop limit exceeded', 'The operation was aborted.'],
    },
    pid: '6540d96fb2f6fa00ba726113',
    logLevel: 0,
    traceKey: 'trace-x',
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
