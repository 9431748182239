import { createSlice } from '@reduxjs/toolkit';

interface initState {
  video_timbre: any[];
  videoInfo: {
    docsName: string | undefined;
    videoUrl: string | undefined;
    duration: string;
  };
  imageDerive: string;
}

const initialState: initState = {
  video_timbre: [],
  videoInfo: {
    docsName: undefined,
    videoUrl: undefined,
    duration: '',
  },
  imageDerive: '',
};

export const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    // 音色列表数据
    setVideoTimbreList: (state, action) => {
      state.video_timbre = action.payload;
    },
    setVideoInfo: (state, action) => {
      state.videoInfo = action.payload;
    },

    setImageDerive: (state, action) => {
      state.imageDerive = action.payload;
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const { setVideoTimbreList, setImageDerive } = videoSlice.actions;

export default videoSlice.reducer;
