import { Modal, Button, message as $message } from 'antd';
import { useState, useEffect, forwardRef, Ref, useImperativeHandle, useRef } from 'react';
import { useAppSelector } from '@/store/hooks';
import classnames from 'classnames';

import './index.less';
import tiyan from '@/assets/recharge/tiyan.png';
import tiyan1 from '@/assets/recharge/tiyan1.png';
import tiyan2 from '@/assets/recharge/tiyan2.png';
//import tiyan_big from '@/assets/recharge/tiyan_big.png';
//import tiyan1_big from '@/assets/recharge/tiyan1_big.png';
//import tiyan2_big from '@/assets/recharge/tiyan2_big.png';
import yunduoPng from '@/assets/yunduo.png';
import yunduoYDMB from '@/assets/yunduo-ydmb.png';
import ConsultModal from '@/components/ConsultModal';

// API
import { memberPackageList } from '@/config/api/user';

const originFlag = window.location.hostname.includes('skyink');
const packageTitleBgList = [tiyan, tiyan1, tiyan2];
//const packageBigBgList = [tiyan_big, tiyan1_big, tiyan2_big];
const tagBgColor = ['#ffffff', '#135EFF', '#f4b24d'];
const packageBtnBg = [
  'linear-gradient(51deg, #9398B0 0%, #AFB7DF 96%)',
  'linear-gradient(51deg, #C58A30 0%, #F4B24D 97%)',
  'linear-gradient(51deg, #444444 0%, #1D0B1C 100%)',
];

//// 企业套餐数据
//const CorporatePackage = {
//  goods_id: 10,
//  goods_title: '定制套餐',
//  goods_desc: '适合内容方企业用户使用',
//  goods_tag: '热卖强力推荐',
//  goods_type: 0,
//  original_price: '',
//  payment_price: '',
//  coin: 0,
//  term: '',
//  service: [
//    '定制化服务',
//    '无限次普通仿写/文案生成',
//    '支持企业子账号',
//    '有效周期365天',
//    '7*24h专属资深客服',
//  ],
//};

//let timer: any = null;
//let qrUrlTimer: any = null;

const Recharge = forwardRef((_props, ref: Ref<any>) => {
  const accountInfo: any = useAppSelector((state) => state.init.account_info);

  const [rechargeModal, setRechargeModal] = useState<boolean>(false);
  // 套餐包
  const [packageList, setPackageList] = useState([]);

  const refConsultModal = useRef<any>(null);

  function init() {
    setRechargeModal(true);
  }

  /**
   * 当前套餐包数据
   */
  function handlePackage() {
    refConsultModal?.current.init();
  }

  function handleRechargeCancel() {
    setRechargeModal(false);
  }

  /**
   * 获取会员套餐数据
   */
  function getMemberPackageList() {
    memberPackageList().then((res: any) => {
      const { code, data, msg } = res;
      if (code !== 0) {
        return $message.error(msg);
      }
      setPackageList(data);
    });
  }

  useEffect(() => {
    getMemberPackageList();
  }, []);

  useImperativeHandle(ref, () => ({
    init,
  }));

  return (
    <>
      <Modal
        wrapClassName="recharge"
        width={860}
        open={rechargeModal}
        centered
        maskClosable={false}
        onCancel={handleRechargeCancel}
        footer={null}
      >
        <div className="modalHeader">
          <h3>
            套餐权益 <span>巨划算！</span>
          </h3>
          <p>
            {accountInfo.company_id === 0 && accountInfo.type !== 1
              ? '免费用户限时享受每天5轮对话和20次应用权益，升级套餐畅享妙笔'
              : '三大套餐权益供您选择，可根据需求挑选合适您的权益'}
          </p>
        </div>
        <div className="rechargeContent">
          {packageList.map((item: any, index: number) => (
            <div className="itemRecharge" key={item.goods_id}>
              <div className="itemHead">
                {item.goods_tag && (
                  <span className="tag" style={{ background: tagBgColor[index] }}>
                    {item.goods_tag}
                  </span>
                )}
                <img src={packageTitleBgList[index]} alt="" />
                <div className="itemTitle">
                  <h3>{item.goods_title}</h3>
                  <p>{item.goods_desc}</p>
                </div>
              </div>
              <div className="itemPrice">
                {item.payment_price ? (
                  <>
                    <p
                      className={classnames('price', {
                        noOriginPrice: item.payment_price === item.original_price,
                      })}
                    >
                      ￥<span>{item.payment_price / 100}</span>
                    </p>
                    {item.payment_price !== item.original_price && (
                      <p className="original_price">
                        原价：
                        <span>
                          ￥<s>{item.original_price / 100}</s>
                        </span>
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p className="companyPrice">企业团队请咨询商务经理</p>
                  </>
                )}
                <Button
                  size="large"
                  style={{
                    background: packageBtnBg[index],
                  }}
                  onClick={handlePackage}
                >
                  立即咨询
                </Button>
              </div>
              <div className="itemText">
                {item.service.map((txt: string, idx: number) => {
                  return (
                    <p key={idx}>
                      <span></span>
                      {txt}
                      {idx === 0 && item.original_price && (
                        <img src={originFlag ? yunduoYDMB : yunduoPng} className="yunduoIcon" />
                      )}
                    </p>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <ConsultModal ref={refConsultModal} />
    </>
  );
});
Recharge.displayName = 'RechargeModal';

export default Recharge;
