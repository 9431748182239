export const WHALE_WISDOM_CLOUDS_TOKEN = 'USER_TOKEN'; //请求token

//  请求token别名
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
// 用于绑定的临时暂存
export const BIND_TOKEN = 'BIND_TOKEN';
// Cid
export const COMPANY_ID = 'COMPANY_ID';
// 用户信息
export const USER_INFO = 'USER_INFO';
// 用户类型
export const USER_TYPE = 'USER_TYPE';
//  微信公众号唯一id
export const WXUID = 'WXUID';
//  url 扩展信息
export const URL_EXPEND = 'URL_EXPEND';
// 邀请人信息
export const INVITE_TOKEN = 'INVITE_TOKEN';
// 路由进入标记
export const ROUTER_FROM = 'ROUTER_FROM';

// ai模板数据文档
export const TEMPLATE_URL =
  'https://cdn-lec-public.netjoy.com/lec/local/0/190/0f328f87c21ed1a9d7d778ca53ca2e9d/新文稿模板.xlsx';

// 生产域名
export const HOSTARR = [
  'https://www.brainywhale.com',
  'https://skyink.netjoy.com',
  'https://test-jzy.netjoy.com',
  'https://test-skyink.netjoy.com',
  'http://localhost:3001',
];

// 上传图片的类型
export const uploadImageTypeArr = ['image/jpeg', 'image/jpg', 'image/png'];
// 上传视频的类型
export const uploadVideoClasses = ['video/mp4', 'video/x-msvideo', 'video/avi', 'video/quicktime'];

// 预设的颜色
export const presetColors = [
  '#000000',
  '#333333',
  '#555555',
  '#777777',
  '#A9A9A9',
  '#C0C0C0',
  '#E0E0E0',
  '#E5E5E5',
  '#F2F2F2',
  '#FFFFFF',
  '#DF5E97',
  '#E45F5F',
  '#EBA64D',
  '#DEA245',
  '#499E8E',
  '#51A294',
  '#3E95EF',
  '#4E81F5',
  '#8372F6',
  '#D960D7',
];
