export const themeConfig = {
  token: {
    colorInfo: '#135EFF',
    colorPrimary: '#135EFF',
    colorBgLayout: '#F3F5F9',
    layoutHeaderColor: 'white',
    colorTextHeading: 'rgba(0,0,0,0.85)',
    borderRadius: 4,
    borderRadiusLG: 4,
  },
  //colorTextLightSolid
  components: {
    Tooltip: {
      fontSize: 12,
      colorBgDefault: 'rgba(0,0,0,0.75)',
      colorTextLightSolid: 'block',
    },
    Menu: {
      itemBorderRadius: 2,
    },
  },
};
